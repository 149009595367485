<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form class="mt-50" @submit.prevent="handleSubmit(handleCreateItem)">
      <validation-provider
        #default="validationContext"
        name=" "
        rules="required"
      >
        <b-form-group
          :label="$t('backoffice.settings.community-name') + ' *'"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="itemInput.name[selected]"
            type="text"
            maxlength="150"
            :disabled="isSaving"
            :placeholder="$t('form-create-item.name-placeholder')"
            rules="required"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- IS PUBLISHED -->
      <b-form-group v-if="!fromOnboarding">
        <b-checkbox
          id="published"
          v-model="itemInput.isPublished"
          class="ml-25 zindex-0"
        >
          {{ $t("form.actions.publish") }}
        </b-checkbox>
      </b-form-group>

      <div class="float-right mb-50">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isSaving"
          type="submit"
        >
          <span v-if="isSaving" class="d-flex"
            ><b-spinner class="mr-1" small variant="white" label="Loading..." />
            {{ $t("form.actions.save") }}
          </span>
          <span v-else>{{ $t("form.actions.save") }}</span>
        </b-button>
      </div>
    </b-form>
    <error-modal
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      :title="$t('appsumo.errors-modal.title')"
      :subtitle="$t('appsumo.errors-modal.subtitle')"
    />
  </validation-observer>
  <!--   </tab-content>
  </form-wizard> -->
</template>

<script>
import Ripple from 'vue-ripple-directive';
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import { required } from "@validations";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import FileTypes from "@/@core/constants/FileTypes";
import PlacesMapSelect from "@core/components/places-map-input/PlacesMapSelect.vue";
import FileUpload from "@core/components/files/FileUpload.vue";
import FilePreview from "@core/components/files/FilePreview.vue";
import ErrorModal from "@core/components/modal/ErrorModal.vue";

export default {
  name: "MembershipEventCreateModal",
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    FilePreview,
    PlacesMapSelect,
    FileUpload,
    FormWizard,
    TabContent,
    ErrorModal,
  },
  directives: {
    Ripple,
  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  props: {
    typeOptions: { type: Array, required: false },
    itemType: { type: String, required: true },
    canCreateOrganization: { type: Boolean, default: false },
    selected: {
      type: String,
      default: "",
    },
    typeSelected: { type: [String, Number], default: null },
    fromOnboarding: { type: Boolean, default: false },
    joinAsMember: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      invalidStartDate: false,
      invalidEndDate: false,
      isLoadingNextPage: false,
      hasMore: false,
      isModalVisible: false,
      currentPage: 0,
      bannerURL: null,
      selectedFilter: "upcoming",
      itemInput: {
        slug: null,
        roomKey: null,
        showCreated: true,
        isPublished: true,
        startDate: null,
        startDateUnix: null,
        endDate: null,
        endDateUnix: null,
        capacity: null,
        headline: {},
        name: {},
      },
      formError: null,
      results: [],
      selectedTypeOption: 27413,
      placePublishOption: 0,
      required,
      modalityOption: 0,
      imageSrc: null,
      locations: [],
      image: null,
      accessibilityOption: this.itemType === "organizations" ? 2 : 0,
      templates: [
        { name: this.$t("events.actions.template.no-template"), key: 0 },
        { name: this.$t("events.actions.template.default-template"), key: 1 },
      ],
      layoutTemplate: 1,
      accessibilityOptions: [
        {
          code: 0,
          accessibility: this.$t(
            "events.actions.accessibility.without-request"
          ),
        },
        {
          code: 1,
          accessibility: this.$t("events.actions.accessibility.with-request"),
        },
        {
          code: 2,
          accessibility: this.$t("events.actions.accessibility.invitation"),
        },
      ],
      modalityOptions: [
        { code: 0, modality: this.$t("events.event-details.online") },
        { code: 1, modality: this.$t("events.event-details.hybrid") },
        { code: 2, modality: this.$t("events.event-details.on-site") },
      ],
      placePublishOptions: [
        { code: 0, place: this.$t("events.event-details.here-place") },
        { code: 1, place: this.$t("events.event-details.external-place") },
        { code: 2, place: this.$t("events.event-details.hybrid-place") },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    membersError() {
      return this.$store.getters.membersError;
    },
    rooms() {
      return this.$store.getters.rooms?.unpaginated;
    },
    roomOptions() {
      return this.rooms.map((x) => ({
        label: x.name,
        key: x.key,
      }));
    },
    FileTypes() {
      return FileTypes;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isMobile() {
      return window.innerWidth < 576;
    },
    set() {
      return !["organizations"].includes(this.itemType);
    },
    redirectAfterCreate() {
      return !["organizations"].includes(this.itemType);
    },
    whereIsPublished() {
      return !["organizations", "services"].includes(this.itemType);
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  async created() {
    this.isLoading = true;
    if (this.itemType === "challenges") {
      this.selectedTypeOption =
        this.typeOptions.length > 0
          ? this.typeSelected
            ? this.typeSelected
            : this.typeOptions[0].code
          : null;
    }
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    unixTimestamp(date) {
      const newDate = new Date(date);
      return Math.floor(newDate.getTime() / 1000);
    },
    setStart(data) {
      // this.itemInput.startDate = data;
      this.itemInput.startDateUnix = this.unixTimestamp(data);
    },
    setEnd(data) {
      // this.itemInput.endDate = data;
      this.itemInput.endDateUnix = this.unixTimestamp(data);
    },
    async fetchData() {},
    // Create item
    async handleCreateItem() {
      this.isSaving = true;
      if (!this.itemInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        let response = {};
        const startDate = this.itemInput.startDateUnix;
        const endDate = this.itemInput.endDateUnix;
        const file = {};
        if (this.image) file.logoURL = this.image;
        if (this.bannerURL) file.bannerURL = this.bannerURL;
        const requestConfig = {
          isEvent: this.itemType === "events" ? 1 : 0,
          isProject: this.itemType === "projects" ? 1 : 0,
          isChallenge: this.itemType === "challenges" ? 1 : 0,
          isCourse: this.itemType === "courses" ? 1 : 0,
          isOrganization: this.itemType === "organizations" ? 1 : 0,
          isService: this.itemType === "services" ? 1 : 0,
          name: this.itemInput.name,
          headline: this.itemInput.headline,
          locations: this.itemInput.locations,
          startDate: this.itemInput.startDate ? startDate : null,
          endDate: this.itemInput.endDate ? endDate : null,
          accessibility: this.accessibilityOption,
          modality: this.modalityOption,
          roomKey:
            this.modalityOption > 0 && this.itemInput.roomKey != null
              ? this.itemInput.roomKey
              : null,
          thumbnailURL: this.itemInput.thumbnailURL,
          published: this.itemInput.isPublished,
          showCreated: this.itemInput.showCreated,
          applyTemplate: this.layoutTemplate,
          registrationURL: this.itemInput.registrationURL,
          joinURL: this.itemInput.joinURL,
          withShared: true,
          isMeeting: 0,
          joinAsMember: this.joinAsMember,
          type: this.typeOptions ? this.selectedTypeOption : null,
          ...(this.modalityOption === 1 || this.modalityOption === 2
            ? { capacity: parseInt(this.itemInput.capacity, 10) }
            : ""),
        };
        if (this.canCreateOrganization) {
          requestConfig.canCreateOrganization = this.canCreateOrganization;
        }
        response = await this.$store.dispatch("createItem", {
          item: {
            itemType: "communitiesOthers",
            storedKey: this.itemType,
            requestConfig,
          },
          file,
          noSet: this.set,
        });
        this.resetForm();
        this.isSaving = false;
        this.itemInput = {
          showCreated: true,
          isPublished: true,
          startDate: null,
          startDateUnix: null,
          endDate: null,
          endDateUnix: null,
          capacity: null,
          headline: {},
          name: {},
        };
        this.image = null;
        this.bannerURL = null;
        this.formError = null;
        this.$emit("create-item", true);
        this.notifySuccess(this.$t("success-message.general-success-create"));
        if (this.redirectAfterCreate) {
          window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
            "{subdomain}",
            "app"
          ).replace("{slug}", response.data.slug)}/backoffice/settings`;
        }
        return;
      } catch {
        if (this.membersError !== "" && this.currentUser.appSumoID != null) {
          this.$bvModal.show("error-modal");
        } else {
          this.notifyError(this.$t("error-message.general-error"));
        }
        this.isSaving = false;
      }
    },
    resetForm() {
      this.$emit("close-modal", false);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
.wizard-tap-content {
  background-color: transparent !important;
}
</style>
